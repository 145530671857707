<template>
  <div class="containerLabsPage pt-4 w-100">
    <custom-scrollbar>
      <b-container fluid>
        <div class="h3 mb-4">
          Заказы
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <b-form-select
            v-model="selectedStatus"
            :options="statusOptions"
            class="w-100"
            @change="fetchOrders"
          />
        </div>
        <div class="mt-4 mb-4 d-flex">
          <b-input-group class="bg-white rounded">
            <b-form-input
              v-model="filter"
              placeholder="Поиск..."
              type="search"
              @keyup.enter="fetchOrders"
            />
            <b-input-group-prepend>
              <b-button
                class="rounded"
                variant="outline-primary"
                :type="$const.PRIMARY_BUTTON"
                @click="fetchOrders"
              >
                <b-icon icon="search" />
              </b-button>
            </b-input-group-prepend>
          </b-input-group>
        </div>
        <b-overlay
          v-if="!orderListStore || Object.keys(orderListStore).length === 0"
          :show="isLoading"
          no-wrap
        />
        <transition
          mode="out-in"
          name="component-fade"
        >
          <div v-if="orderListStore">
            <div v-if="Object.keys(orderListStore).length !== 0">
              <b-table
                v-if="orderListStore"
                :busy="isLoading"
                :current-page="currentPage"
                :fields="fields"
                :filter="filter"
                :items="orderListStore.data"
                :per-page="0"
                :small="true"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                bordered
                class="bg-white border-secondary mt-4"
                hover
              >
                <template v-slot:cell(createdAt)="row">
                  <span>{{ createDateFormat(row) }}</span>
                </template>
                <template v-slot:cell(name)="row">
                  <span>{{
                    personName(row)
                  }}</span>
                </template>
                <template v-slot:cell(status)="row">
                  <span>{{ orderStatus(row) }}</span>
                </template>
                <template v-slot:cell(partner)="row">
                  <span>{{ row.item.partner.name }}</span>
                </template>
                <template #cell(actions)="row">
                  <router-link
                    :to="`/laboratory-order-info/${row.item.id}`"
                  >
                    <b-button
                      class="mr-2"
                      size="sm"
                      variant="primary"
                      :type="$const.PRIMARY_BUTTON"
                    >
                      Инфо
                    </b-button>
                  </router-link>
                </template>
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-2" />
                    <strong>Загрузка...</strong>
                  </div>
                </template>
              </b-table>
              <div
                class="mt-3"
              >
                <div
                  v-show="orderListStore"
                  class="crm-pagination"
                >
                  <b-dropdown
                    id="dropdown-1"
                    :text="`${perPage}`"
                    class="m-md-2"
                    size="sm"
                    split
                    split-variant="outline-primary"
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                  >
                    <b-dropdown-item @click="tableSizeHandler(10)">
                      10
                    </b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item @click="tableSizeHandler(25)">
                      25
                    </b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item @click="tableSizeHandler(50)">
                      50
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-pagination
                    v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="+allCount"
                    pills
                    style="padding-top: 16px"
                    @input="onClickNextPage"
                  />
                </div>
              </div>
            </div>
            <span v-else>Ничего не найдено</span>
          </div>
        </transition>
      </b-container>
    </custom-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as types from '@/store/types';
import { format, parseISO } from '@evd3v/date-fns';

export default {
  name: 'LaboratoryOrders',
  page: {
    title: 'CRM Doctis - Заказы',
  },
  components: {},
  data() {
    return {
      sortBy: 'createdAt',
      sortDesc: true,
      fields: [
        {
          key: 'number',
          label: 'Номер',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'createdAt',
          label: 'Дата',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'status',
          label: 'Статус',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'partner',
          label: 'Партнер',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'name',
          label: 'Заказчик',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'branch.address',
          label: 'Адрес',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'actions',
          label: 'Подробнее',
          tdClass: 'align-middle',
          class: 'text-center',
        },
      ],
      currentPage: 1,
      perPage: 10,
      limit: 10,
      filter: null,
      rows: 1,
      allCount: null,
      isActive: true,
      isLoading: false,
      selectedStatus: null,
      statusOptions: [
        {
          value: null,
          text: 'Выберите статус заказа',
        },
        {
          value: 0,
          text: 'Ожидает оплаты',
        },
        {
          value: 1,
          text: 'Оплачен',
        },
        {
          value: 2,
          text: 'Выполнен',
        },
        {
          value: 3,
          text: 'Отменен',
        },
        {
          value: 4,
          text: 'Размещен у партнера',
        },
        {
          value: 5,
          text: 'В процессе выполнения',
        },
      ],
    };
  },
  computed: {
    paginationParams() {
      return {
        limit: this.limit,
        offSet: this.offSet,
        isActive: this.isActive,
        Status: this.selectedStatus,
        save: true,
      };
    },
    orderListStore() {
      return this.$store.state.Laboratories.orderList;
    },
    ...mapGetters({
      laboratoryOrderList: types.ORDERS_LIST_GET,
    }),
    offSet() {
      return (this.currentPage - 1) * this.limit;
    },
  },
  async created() {
    await this.fetchOrders();
  },
  beforeDestroy() {
    this.$store.commit(this.$types.ORDER_ITEMS_SET, []);
  },
  methods: {
    async tableSizeHandler(mount) {
      this.perPage = mount;
      this.limit = this.perPage;
      await this.fetchOrders();
    },
    orderStatus(row) {
      switch (row.item.status) {
        case 'WaitPay':
          return 'Ожидает оплаты';
        case 'Paid':
          return 'Оплачен';
        case 'Completed':
          return 'Выполнен';
        case 'Canceled':
          return 'Отменен';
        case 'Received':
          return 'Размещен у партнера';
        case 'InProgress':
          return 'В процессе выполнения';
        default:
          return '-';
      }
    },
    createDateFormat(row) {
      return row ? format(parseISO(row.value), 'dd.MM.yyyy') : null;
    },
    personName(row) {
      let name = '';

      if (row.item.person) {
        if (row.item.person.lastName) {
          name += row.item.person.lastName;
        }
        if (row.item.person.firstName) {
          name += ` ${row.item.person.firstName}`;
        }
        if (row.item.person.middleName) {
          name += ` ${row.item.person.middleName}`;
        }
      }

      return name;
    },
    async fetchOrders() {
      this.isLoading = true;
      const data = await this.$store.dispatch(this.$types.GET_LABORATORY_ORDERS, this.paginationParams);
      this.allCount = data.xTotalCount;
      this.isLoading = false;
    },
    async onClickNextPage() {
      await this.fetchOrders();
    },
  },
};
</script>

<style lang="scss" scoped>

.policy-list-content {
  height: calc(100vh - #{$header-height});
  transition: height 0.3s linear;
}

.crm-table {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.crm-input {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.crm-pagination {
  display: flex;
  align-items: center;
}

.crm-icon-delete {
  cursor: pointer;
  transition: .3s all;

  &:hover {
    rect {
      stroke: $blue;
      opacity: 0.5;
    }
  }
}

.crm-icon-edit {
  cursor: pointer;
  transition: .3s all;

  path {
    fill: $blue;
  }

  &:hover {
    rect {
      stroke: $blue;
      opacity: 0.5;
    }
  }
}
</style>
